import React from 'react'
import { Order } from '@gelatonetwork/limit-orders-lib'
import { useCurrency } from '../../hooks/Tokens'
import { Currency, Price } from 'tombswap-sdk'
import { ethers } from 'ethers'
import { tryParseAmount } from '../../state/swap/hooks'
import { useTradeExactIn } from '../../hooks/Trades'

const isNativeToken = (address: string) => {
  return address.toLowerCase() === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
}

const getRelativePercentageDiff = (a: number, b: number) => {
  return 100 * Math.abs((a - b) / ((a + b) / 2))
}

export default function OrderItems({
  order,
  showExecutionTime,
  showLink,
  showCancel,
  columns,
  index
}: {
  order: Order
  showExecutionTime?: boolean
  showLink?: boolean
  showCancel?: boolean
  columns: number
  index?: number
}) {
  let inputTokenDetails = useCurrency(order.inputToken)
  if (isNativeToken(order.inputToken)) {
    inputTokenDetails = Currency.ETHER
  }
  let outputTokenDetails = useCurrency(order.outputToken)
  if (isNativeToken(order.outputToken)) {
    outputTokenDetails = Currency.ETHER
  }

  const inputTokenName = inputTokenDetails?.symbol
    ? inputTokenDetails?.symbol
    : isNativeToken(order.inputToken)
    ? Currency.ETHER.symbol || ''
    : 'Unknown'
  const outputTokenName = outputTokenDetails?.symbol
    ? outputTokenDetails.symbol
    : isNativeToken(order.outputToken)
    ? Currency.ETHER.symbol || ''
    : 'Unknown'
  const limitInputAmount = ethers.utils.formatUnits(order.inputAmount, inputTokenDetails?.decimals || '18')
  const limitOrderAdjustedMinReturn = ethers.utils.formatUnits(
    order.adjustedMinReturn,
    inputTokenDetails?.decimals || '18'
  )
  const limitOrderMinReturn = ethers.utils.formatUnits(order.minReturn, inputTokenDetails?.decimals || '18')

  const inputParsedAmount = tryParseAmount(
    ethers.utils.formatUnits(order.inputAmount, inputTokenDetails?.decimals || '18'),
    useCurrency(isNativeToken(order.inputToken) ? 'ETH' : order.inputToken) ?? undefined
  )
  const marketTrade = useTradeExactIn(inputParsedAmount, outputTokenDetails ?? undefined)

  const orderPrice = new Price(
    inputTokenDetails as any,
    outputTokenDetails as any,
    order.inputAmount,
    Number(order.adjustedMinReturn) !== 0 ? order.adjustedMinReturn : order.minReturn
  )

  const executionPair = `${orderPrice.baseCurrency?.symbol ?? '--'} / ${orderPrice.quoteCurrency?.symbol ?? '--'}`
  const executionPrice = `${orderPrice.toSignificant(6) ?? '--'}`

  const executionPairInverted = `${orderPrice.invert().baseCurrency?.symbol ?? '--'} / ${orderPrice.invert()
    ?.quoteCurrency?.symbol ?? '--'}`
  const executionPriceInverted = `${orderPrice.invert()?.toSignificant(6) ?? '--'}`

  const marketAndOrderReturnPercentageDiff = marketTrade
    ? getRelativePercentageDiff(Number(marketTrade.outputAmount.toSignificant(10)), Number(limitOrderMinReturn))
    : 0

  // const inputToken = GuseCurrency(order.inputToken);
  // const outputToken = GuseCurrency(order.outputToken);

  // const inputAmount = useMemo(
  //     () =>
  //         inputToken && order.inputAmount
  //             ? CurrencyAmount.fromRawAmount(inputToken, order.inputAmount)
  //             : undefined,
  //     [inputToken, order.inputAmount]
  // );

  // const outputAmount = useMemo(
  //     () =>
  //         outputToken
  //             ? CurrencyAmount.fromRawAmount(outputToken, order.minReturn)
  //             : undefined,
  //     [outputToken, limitOrderAdjustedMinReturn]
  // );

  // const {
  //     gasPrice,
  //     realExecutionPrice, // returns @uniswap/sdk-core Price object
  //     realExecutionPriceAsString,
  // } = useGasOverhead(inputAmount, outputAmount);

  return (
    <div
      className={` ${
        index ? (index % 2 === 1 ? 'dark:bg-liveOrderBg"' : '') : ''
      } tableRow1 grid md:grid-cols-${columns} lg:grid-cols-${columns} font-tin uppercase text-xl items-center border-b text-center`}
    >
      <span className="text-xs leading-tight border-r py-3">
        {inputTokenName?.toUpperCase() ?? '--'}/{outputTokenName?.toUpperCase() ?? '--'}
      </span>
      <span className="text-xs leading-tight border-r py-3 ">
        {executionPrice} {executionPair}
      </span>
      <span className="text-xs leading-tight border-r py-3 ">
        {executionPriceInverted} {executionPairInverted}
      </span>
      <span className="text-xs leading-tight border-r py-3 ">
        {parseFloat(limitInputAmount).toFixed(4)} {inputTokenName}
      </span>
      <span className="text-xs leading-tight border-r py-3 ">
        {parseFloat(limitOrderAdjustedMinReturn).toFixed(4)} {outputTokenName}
      </span>
      <span className="text-xs leading-tight border-r py-3 ">
        {parseFloat(limitOrderMinReturn).toFixed(4)} {outputTokenName}
      </span>
      <span className="text-xs leading-tight border-r py-3 ">{marketAndOrderReturnPercentageDiff.toFixed(0)} %</span>
      <span className="text-xs leading-tight border-r py-3 ">0.01</span>
      <span className="text-xs leading-tight border-r py-3  ">15 TOMB</span>
      {showExecutionTime && (
        <span className="text-xs leading-tight border-r py-3 ">
          {getFormattedTime(order.createdAt)}
          {/*8:34:18 AM 03/17/2022*/}
        </span>
      )}
      {showLink && (
        <span className="text-xs leading-tight border-r py-3 underline cursor-pointer">
          <a
            rel="noopener noreferrer"
            target={'_blank'}
            href={`https://ftmscan.com/tx/${order?.executedTxHash ?? order.createdTxHash}`}
            className="btn w-36 h-8 font-bold text-center pt-1 cursor-pointer"
          >
            LINK
          </a>
        </span>
      )}
      {showCancel && <span className="text-xs leading-tight py-3 underline cursor-pointer">Cancel</span>}
    </div>
  )
}

const getFormattedTime = (time: string, locale = 'en-US') => {
  const parsedTime = new Date(parseInt(time + '000'))
  const formattedDate = parsedTime.toLocaleDateString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
  const formattedTime = parsedTime.toLocaleTimeString(locale)

  return `${formattedTime} ${formattedDate}`
}
