import React from 'react'
import { Currency, Price } from 'tombswap-sdk'
import { ethers } from 'ethers'

import { tryParseAmount, useSelectedCurrencies } from '../../state/swap/hooks'
import { useTradeExactIn } from '../../hooks/Trades'

const isNativeToken = (address: string) => {
  return address.toLowerCase() === '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
}

const getRelativePercentageDiff = (a: number, b: number) => {
  return 100 * Math.abs((a - b) / ((a + b) / 2))
}

export default function OrderPreviewItem({
  order,
  columns,
  index
}: {
  order: {
    inputToken: string
    outputToken: string
    inputAmount: string
    minReturn: string
  }
  columns: number
  index?: number
}) {
  const { inputCurrency, outputCurrency } = useSelectedCurrencies()

  const inputTokenName = inputCurrency?.symbol
    ? inputCurrency?.symbol
    : isNativeToken(order.inputToken)
    ? Currency.ETHER.symbol || ''
    : 'Unknown'

  const outputTokenName = outputCurrency?.symbol
    ? outputCurrency.symbol
    : isNativeToken(order.outputToken)
    ? Currency.ETHER.symbol || ''
    : 'Unknown'

  const limitInputAmount = ethers.utils.formatUnits(order.inputAmount, inputCurrency?.decimals || '18')
  const limitOrderMinReturn = ethers.utils.formatUnits(order.minReturn, inputCurrency?.decimals || '18')

  const inputParsedAmount = tryParseAmount(
    ethers.utils.formatUnits(order.inputAmount, inputCurrency?.decimals || '18'),
    inputCurrency ?? undefined
  )
  const marketTrade = useTradeExactIn(inputParsedAmount, outputCurrency ?? undefined)

  const orderPrice = new Price(inputCurrency as any, outputCurrency as any, order.inputAmount, order.minReturn)

  const executionPair = `${orderPrice.baseCurrency?.symbol ?? '--'} / ${orderPrice.quoteCurrency?.symbol ?? '--'}`
  const executionPrice = `${orderPrice.toSignificant(6) ?? '--'}`
  const executionPairInverted = `${orderPrice.invert().baseCurrency?.symbol ?? '--'} / ${orderPrice.invert()
    ?.quoteCurrency?.symbol ?? '--'}`
  const executionPriceInverted = `${orderPrice.invert()?.toSignificant(6) ?? '--'}`

  const marketAndOrderReturnPercentageDiff = marketTrade
    ? getRelativePercentageDiff(Number(marketTrade.outputAmount.toSignificant(10)), Number(limitOrderMinReturn))
    : 0

  return (
    <div
      className={` ${
        index ? (index % 2 === 1 ? 'dark:bg-liveOrderBg' : '') : ''
      } tableRow1 grid md:grid-cols-${columns} lg:grid-cols-${columns} font-tin uppercase text-xl items-center text-center`}
    >
      <span className="text-xs leading-tight border-r py-3">
        {inputTokenName?.toUpperCase() ?? '--'}/{outputTokenName?.toUpperCase() ?? '--'}
      </span>
      <span className="text-xs leading-tight border-r py-3 ">
        {executionPrice} {executionPair}
      </span>
      <span className="text-xs leading-tight border-r py-3 ">
        {executionPriceInverted} {executionPairInverted}
      </span>
      <span className="text-xs leading-tight border-r py-3 ">
        {parseFloat(limitInputAmount).toFixed(4)} {inputTokenName}
      </span>
      <span className="text-xs leading-tight border-r py-3 ">
        {parseFloat(limitOrderMinReturn).toFixed(4)} {outputTokenName}
      </span>
      <span className="text-xs leading-tight border-r py-3 ">{marketAndOrderReturnPercentageDiff.toFixed(0)} %</span>
    </div>
  )
}
